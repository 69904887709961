/* You can add global styles to this file, and also import other style files */

*, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --logo-bg: rgba(1, 27, 49, 1); /*#FFFDE7*/
    --logo-color: rgba(255, 179, 0, 1); /*#8b0000*/
    --cmp-box-bg: hsl(36deg 100% 85%);
    --text-color-red: #b71c1c;
    --text-color-green: #2E7D32;
    --text-color-grey: #757575;
    --light-yellow-bg: rgb(255, 255, 224, 1);
    --light-blue-bg: rgb(173, 216, 230, 1);
    --light-green-bg: rgb(209 245 211);
    --label-color: rgb(26 35 126);
    --blue-bg: hsl(235deg 74% 28%);
}

html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif; 
    box-sizing: border-box;
    line-height: 1.5;
}

.container {
    max-width: 1000px;
    margin: auto;
}

.toolbar-empty-space {
    flex: 1 1 auto;
}

.new-fab-button {
    position: fixed;
    bottom: 6em;
    right: 3em;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.new-fab-button .mat-button-wrapper {
    display: inline-block;
    position: absolute;
    top: 16px;
    left: 16px;
    padding: 0;
    transform: rotate(0);
    transition: all .3s ease-in-out;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.new-fab-button .mat-mdc-fab .mat-button-wrapper {
    padding: 0;
    vertical-align: unset;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.new-fab-button .mat-mdc-fab .mat-button-wrapper>* {
    vertical-align: unset;
}

.new-fab-button mat-icon {
    font-size: 24px;
    line-height: 1;
    text-align: center;
    display: inline-block;
}

.button-section {
    display: flex;
    justify-content: space-around;
}

.button-section button {
    margin: 0 0.25em;
}

// .button-section button {
//     margin-right: 0.5em;
// }

.action-success {
    font-size: 2em;
    color: var(--text-color-green);
}

.action-success .button-section {
    justify-content: center;
}

.action-success mat-icon {
    font-size: 4em;
    width: auto;
    height: auto;
}

.lightBlue-bg {
    background-color: var(--light-blue-bg);
}

.lightYellow-bg {
    background-color: var(--light-yellow-bg);
}

.label {
    font-weight: 600;
    color: var(--label-color);
}

.bold-text {
    font-weight: 600;
}

/* Div content */
.div-content-header {
    min-height: 3em;
    display: flex;
    align-items: center;
    background-color: var(--blue-bg);
    color: #ffffff;
}
/* End of Div content */

/* Helper styles */

.margin-1x {
    margin: 1em;
}

.margin-b-1x {
    margin-bottom: 1em;
}

.margin-t-1x {
    margin-top: 1em;
}

.margin-tb-1x {
    margin-top: 1em;
    margin-bottom: 1em;
}

.padding-1x {
    padding: 1em;
}

.padding-b-1x {
    padding-bottom: 1em;
}

.padding-lr-1x {
    padding-left: 1em;
    padding-right: 1em;
}

.border-1x {
    border: 1px solid rgba(1, 27, 49, 1);
}

.centered {
    text-align: center;
    justify-content: center;
}

.full-width {
    width: 100%;
}

.two-column-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
}

.flex-box {
    display: flex;
    flex-direction: column;
}

.simple-box-shadow {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

/* End of Helper styles */

/* Media queries */
@media screen and (max-width: 600px) {
    .two-column-grid {
        grid-template-columns: 1fr;
    }
}
